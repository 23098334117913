<template>
  <div class="about-us">
    <div class="banner">
      <div class="img-box">
        <img class="img" src="https://df7ocie544p6c.cloudfront.net/images/abust-us.jpg" alt="" />
      </div>

      <div class="top-left-con">
        <span>{{ $t('AboutUs.title') }}</span>
      </div>
      <div class="bottom-left-con"></div>
      <div class="line1"></div>
      <div class="line2"></div>
      <div class="line line3"></div>
      <div class="line line4"></div>
    </div>

    <div class="plan-obj-title">
      <div class="left">
        <p class="line-left1"></p>
        <p class="line-left2"></p>
      </div>

      <p>{{ $t('AboutUs.about_organizer') }}</p>
      <div class="right">
        <p class="line-right1"></p>
        <p class="line-right2"></p>
      </div>
    </div>

    <div class="organizer-container">
      <div class="logo">
        <a href="https://inspiringhk.org/#/" target="_blank">
          <img src="../assets/images/ihk-logo.png" />
        </a>
      </div>
      <div class="text">
        {{ $t('AboutUs.organizer') }}
        <div class="new-para">
          <p>{{ $t('AboutUs.para_1') }}</p>
          <p>{{ $t('AboutUs.para_2') }}</p>
          <div class="email">
            {{ $t('AboutUs.para_3') }}
            <p>{{ $t('AboutUs.para_4') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="plan-obj-title">
      <div class="left">
        <p class="line-left1"></p>
        <p class="line-left2"></p>
      </div>

      <p>{{ $t('AboutUs.about_academy') }}</p>
      <div class="right">
        <p class="line-right1"></p>
        <p class="line-right2"></p>
      </div>
    </div>
    <div class="title-list">
      <div class="title-list">
        <div class="list-item">
          <div class="item-title">
            <p class="title-round1 click-hover" @click="gotoUrl('/inspire')">{{ $t('AboutUs.stage1_title') }}</p>
            <p class="title-round2 click-hover" @click="gotoUrl('/safeguarding')">{{ $t('AboutUs.stage2_title') }}</p>
            <p class="title-round3 click-hover" @click="gotoUrl('/coach-connect')">{{ $t('AboutUs.stage3_title') }}</p>
            <!-- <p class="round-bg"></p>  -->
          </div>
        </div>
      </div>
    </div>
    <div class="plan-obj-title">
      <div class="left">
        <p class="line-left1"></p>
        <p class="line-left2"></p>
      </div>
      <p>{{ $t('AboutUs.project_title') }}</p>
      <div class="right">
        <p class="line-right1"></p>
        <p class="line-right2"></p>
      </div>
    </div>
    <div class="project-effect">
      <div :class="[index === 1 || index === 3 ? 'project-item-right' : '', 'project-item']" v-for="(item, index) in results_list" :key="index">
        <div class="right">
          <div class="tit">
            {{ $t('AboutUs.' + item.tit) }}
          </div>
          <div class="txt">
            <div class="effect-item">
              <div class="effect-item-text">
                {{ $t('AboutUs.' + item.txt1) }}
              </div>
            </div>
          </div>
          <div class="txt">
            <div class="effect-item">
              <div class="effect-item-text">
                {{ $t('AboutUs.' + item.txt2) }}
              </div>
            </div>
          </div>
          <div class="txt">
            <div class="effect-item">
              <div class="effect-item-text">
                {{ $t('AboutUs.' + item.txt3) }}
              </div>
            </div>
          </div>
          <div class="txt" v-if="'txt4' in item">
            <div class="effect-item">
              <div class="effect-item-text">
                {{ $t('AboutUs.' + item.txt4) }}
              </div>
            </div>
          </div>
          <div class="txt" v-if="'txt5' in item">
            <div class="effect-item">
              <div class="effect-item-text">
                {{ $t('AboutUs.' + item.txt5) }}
              </div>
            </div>
          </div>
          <div class="txt" v-if="'txt6' in item">
            <div class="effect-item">
              <div class="effect-item-text">
                {{ $t('AboutUs.' + item.txt6) }}
              </div>
            </div>
          </div>
          <div class="txt" v-if="'txt7' in item">
            <div class="effect-item">
              <div class="effect-item-text">
                {{ $t('AboutUs.' + item.txt7) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <joinUsFooter />
  </div>
</template>

<script>
import JoinUsFooter from '../components/JoinUsFooter'
export default {
  components: {
    JoinUsFooter,
  },
  data() {
    return {
      planList: [
        {
          img: require('../assets/images/coach-1.png'),
          tit: 'plan_tit1',
          txt: 'plan_txt1',
        },
        {
          img: require('../assets/images/coach-2.png'),
          tit: 'plan_tit2',
          txt: 'plan_txt2',
        },
        {
          img: require('../assets/images/schools-3.png'),
          tit: 'plan_tit3',
          txt: 'plan_txt3',
        },
        {
          img: require('../assets/images/schools-4.png'),
          tit: 'plan_tit4',
          txt: 'plan_txt4',
        },
        {
          img: require('../assets/images/parent-5.png'),
          tit: 'plan_tit5',
          txt: 'plan_txt5',
        },
      ],
      stagelist: [
        {
          tit: 'stage1_title',
          txt1: 'stage1_txt1',
          txt2: 'stage1_txt2',
        },
        {
          tit: 'stage2_title',
          txt1: 'stage2_txt1',
          txt2: 'stage2_txt2',
        },
        {
          tit: 'stage3_title',
          txt1: 'stage3_txt1',
          txt2: 'stage3_txt2',
        },
      ],
      results_list: [
        {
          tit: 'results1_tit',
          txt1: 'results1_txt1',
          txt2: 'results1_txt2',
          txt3: 'results1_txt3',
          txt4: 'results1_txt4',
          txt5: 'results1_txt5',
        },
        {
          tit: 'results2_tit',
          txt1: 'results2_txt1',
          txt2: 'results2_txt2',
          txt3: 'results2_txt3',
          txt4: 'results2_txt4',
        },
        {
          tit: 'results3_tit',
          txt1: 'results3_txt1',
          txt2: 'results3_txt2',
          txt3: 'results3_txt3',
          txt4: 'results3_txt4',
          txt5: 'results3_txt5',
          txt6: 'results3_txt6',
          txt7: 'results3_txt7',
        },
      ],
    }
  },
  methods: {
    gotoUrl(url) {
      this.$router.push(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.click-hover:hover {
  cursor: pointer;
}
.organizer-container {
  .text {
    font-size: 26px;
  }
}
.new-para {
  text-align: left;
  font-size: 26px;
  margin-top: 100px;
}
@media screen and (min-width: 481px) {
  .organizer-container {
    margin: 0 10%;
    margin-bottom: 5% !important;
    display: flex;
    flex-direction: row;
    .logo img {
      max-width: 180px;
    }
    .text {
      text-align: justify;
      padding-left: 10%;
    }
  }
}
.new-para {
  text-align: left;
  font-size: 26px;
  margin-top: 100px;
  .email {
    display: flex;
    p {
      color: blue;
      margin-left: 10px;
    }
  }
}
.ihk-logo img {
  width: 180px;
  margin-top: 100px;
}

.organizer-thumb img {
  float: left;
}

.organizer-content {
  float: left;
}

div.effect-item:before {
  content: '';
  width: 13px;
  height: 13px;
  margin-top: 5px;
  border: 1px solid #f15922;
  background-color: #f15922;
  border-radius: 50%;
  color: red;
  position: absolute;
  transform: translateY(2px);
}
div.effect-item-text {
  padding-left: 30px;
}
.about-us {
  .banner {
    height: 620px;
    position: relative;
    .img-box {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 70%;
      transform: skew(-14deg) translateX(8.2%);
      overflow: hidden;
      //background: chartreuse;
      .img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: skew(10deg) translateX(-6%);
      }
    }
    .top-left-con {
      width: 47.25%;
      height: 75%;
      transform: skew(-13.8deg) translateX(-24%);
      position: absolute;
      bottom: 0;
      top: 0;
      background: #f15922;
      z-index: 1;
      font-size: 52px;
      color: #fff;
      font-weight: bold;
      // font-family: "NotoSansCJKtc-Black";
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-left: -10%;
        margin-top: -10%;
        font-weight: bold;
        transform: skew(6deg);
      }
    }
    .bottom-left-con {
      width: 43.5%;
      height: 100%;
      transform: skew(-13.8deg) translateX(-20%);
      position: absolute;
      bottom: 0;
      left: 0;
      background: #f15922;
    }
  }
  .line1 {
    position: absolute;
    top: 12%;
    left: 28%;
    width: 15%;
    height: 2px;
    background: #fff;
    z-index: 3;
  }
  .line2 {
    position: absolute;
    top: 17%;
    left: 27%;
    width: 7%;
    height: 2px;
    background: #fff;
    z-index: 3;
  }
  .line {
    height: 2px;
    background: #fff;
    position: absolute;
    z-index: 3;
  }
  .line3 {
    width: 11%;
    top: 55%;
    left: 0%;
  }
  .line4 {
    width: 35%;
    top: 63%;
    left: 5%;
  }
  .plan-obj-title {
    margin: 4% auto;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f15922;
    font-size: 32px;
    font-weight: bold;
    // font-family: "NotoSansCJKtc-Bold";
    transform: skew(-10deg);
    overflow: visible;
    .left {
      width: 60px;
      height: 50px;
      position: relative;
      .line-left1 {
        width: 30px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 20px;
        left: 12px;
      }
      .line-left2 {
        width: 30px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 28px;
        left: 0px;
      }
    }
    .right {
      width: 60px;
      height: 50px;
      position: relative;
      .line-right1 {
        width: 40px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 20px;
        right: 0px;
      }
      .line-right2 {
        width: 20px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 28px;
        right: 18px;
      }
    }
  }
  .plan-txt {
    width: 90%;
    margin: 0 auto;
    justify-content: center;
    background: #f15922;
    font-size: 29px;
    color: #fff;
    padding: 18px;
    z-index: 2;
    transform: skew(-10deg);
    span {
      display: block;
      font-size: 26px;
      transform: skew(10deg);
    }
  }
  .plan-con {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(241, 89, 34, 0.5);
    margin-top: 250px;
    .plan-list {
      width: 90%;
      margin: -150px auto 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      .item {
        width: 17%;
        display: flex;
        align-items: center;
        flex-direction: column;
        // height: 400px;
        z-index: 1;
        .item-img {
          flex: 1;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-con {
          flex: 1;
          .tit {
            margin: 5% auto;
            font-size: 28px;
            font-size: #000;
            font-weight: 600;
          }
          .txt {
            font-size: 22px;
            margin: 8% auto;
          }
        }
      }
    }
    .plan-txt {
      width: 90%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f15922;
      color: #fff;
      padding: 18px;
      bottom: -70px;
      z-index: 2;
      transform: skew(-10deg);
      span {
        font-size: 30px;
        transform: skew(10deg);
      }
    }
  }
  .title-list {
    width: 100%;
    margin: 50px auto;
    margin-bottom: 0%;
    display: flex;
    justify-content: center;
    .list-item {
      width: 100%;
      // background: red;
      .item-title {
        display: flex;
        position: relative;
        .round-bg {
          position: absolute;
          height: 50px;
          background: #f15922;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          z-index: 1;
        }
        .title-round1 {
          font-size: 25px;
          font-weight: bold;
          position: relative;
          width: 300px;
          height: 300px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ff7e00;
          border-radius: 50%;
          color: #fff;
          padding: 30px;
          letter-spacing: 0;
          z-index: 2;
        }
        .title-round2 {
          font-size: 25px;
          font-weight: bold;
          position: relative;
          width: 300px;
          height: 300px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ff8c00;
          border-radius: 50%;
          color: #fff;
          padding: 30px;
          letter-spacing: 0;
          z-index: 2;
        }
        .title-round3 {
          font-size: 25px;
          font-weight: bold;
          position: relative;
          width: 300px;
          height: 300px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ff9f00;
          border-radius: 50%;
          color: #fff;
          padding: 30px;
          letter-spacing: 0;
          z-index: 2;
        }
      }
      .item-txt {
        width: 70%;
        margin: 12px auto;
        text-align: center;
        padding: 10px;
        min-height: 220px;
        .title {
          font-size: 26px;
          font-weight: bold;
          text-align: center;
        }
        .item-detail {
          font-size: 22px;
          padding: 15px 0;
        }
      }
    }
  }

  .project-effect {
    width: 100%;
    margin: 80px auto 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    .project-item {
      width: 30%;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      margin-bottom: 5%;
      .right {
        margin-left: 8%;
        font-size: 22px;
        text-align: left;
        .tit {
          font-size: 25px;
          font-size: #000;
          font-weight: 600;
          border-bottom: 1px solid #f15922;
          display: inline-block;
          padding-bottom: 10px;
        }
        .txt {
          margin-top: 5%;
          .round {
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 1px solid #f15922;
            border-radius: 50%;
            transform: translateX(5px);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .organizer-container {
    margin: 0 10%;
    margin-bottom: 5% !important;
    display: flex;
    flex-direction: column;
    .logo img {
      max-width: 150px;
    }
    .text {
      font-size: 28px;
      margin-top: 3%;
      text-align: justify;
    }
  }
  div.effect-item:before {
    content: '';
    width: 10px;
    height: 10px;
    margin-top: 1px !important;
    border: 1px solid #f15922;
    border-radius: 50%;
    color: red;
    position: absolute;
    transform: translateY(10px);
  }
  div.effect-item-text {
    margin-top: 10px;
    padding-left: 25px;
  }
  .about-us {
    .banner {
      height: 392px;
      .img-box {
        width: 81%;
        transform: skew(-13deg) translateX(24%);
        .img {
          width: 100%;
          transform: skew(10deg) translateX(-9%);
        }
      }
      .top-left-con {
        width: 49%;
        height: 75%;
        font-size: 35px !important;
        transform: skew(-13.4deg) translateX(-22.3%);
        span {
          margin-left: 10%;
        }
      }
      .bottom-left-con {
        width: 42%;
        transform: skew(-13deg) translateX(-13%);
      }
      .line1 {
        width: 50px;
        height: 2px;
        left: 36%;
        top: 17%;
      }
      .line2 {
        width: 33px;
        height: 2px;
        left: 35%;
        top: 23%;
      }
    }
    .plan-obj-title {
      font-size: 30px;
      font-weight: bold;
      .right {
        width: 50px;
      }
    }
    .plan-txt {
      width: 70%;
      margin: 0 auto;
      margin-bottom: 5%;
      justify-content: center;
      background: #f15922;
      color: #fff;
      padding: 18px;
      z-index: 2;
      transform: skew(-10deg);
      span {
        font-size: 24px;
      }
    }
    .plan-con {
      .plan-list {
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
        .item {
          width: 180px;
          margin: 20px;
          .item-con {
            .tit {
              font-size: 28px;
            }
            .txt {
              font-size: 22px;
              width: 100%;
              text-align: center;
            }
          }
        }
      }
      .bage {
        height: 650px;
        bottom: -160px;
      }
      .plan-txt {
        width: 70%;
        bottom: -45%;
      }
    }
    .title-list {
      width: 80%;
      font-size: 12px;
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .list-item {
        width: 100%;
        transform: none;
        .item-title {
          font-size: 16px;
        }
        .item-txt {
          width: 90%;
          font-size: 12px;
          p {
            width: 100%;
          }
          .title {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            margin: 30px 0;
          }
          .item-detail {
            font-size: 24px;
          }
        }
      }
    }
    .project-effect {
      width: 90%;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 12%;
      .project-item {
        flex-direction: column;
        width: 100%;
        .left {
          .round {
            width: 65px;
            height: 65px;
          }
        }
        .right {
          margin-left: 5%;
          .tit {
            font-size: 30px;
          }
          .txt {
            font-size: 26px;
            letter-spacing: 0px;
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>
